import React from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getTokenFromCookies, required } from './helpers';
import { login } from './actions/Actions';

interface DispatchProps {
    onLogin: (username: string, password: string) => Promise<void>;
}

type FormValues = {
    username: string;
    password: string;
};

const Login: React.FC<DispatchProps & RouteComponentProps> = ({ onLogin, history }) => {
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (getTokenFromCookies()) {
            history.push('/admin');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = React.useCallback(
        (values: FormValues) => {
            setLoading(true);

            onLogin(values.username, values.password)
                .then(() => {
                    history.push('/admin');
                })
                .catch(() => {
                    setLoading(false);
                });
        },
        [onLogin, history]
    );

    const renderForm = React.useCallback(
        ({
            handleSubmit,
            submitting,
            validating,
            hasValidationErrors
        }: FormRenderProps) => (
            <form className='login-form' onSubmit={handleSubmit}>
                <div className='title'>ΜΠΟΣΝΑΚΗΣ ADMINISTRATION</div>
                <Field name='username' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <FontAwesome name='user' className='input-icon' />
                            <input {...input} type='text' placeholder='Username' />
                            {meta.error && meta.touched && (
                                <FontAwesome
                                    className='error-icon'
                                    name='exclamation-circle'
                                    title={meta.error}
                                />
                            )}
                        </div>
                    )}
                </Field>
                <Field name='password' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <FontAwesome name='lock' className='input-icon' />
                            <input {...input} type='password' placeholder='Password' />
                            {meta.error && meta.touched && (
                                <FontAwesome
                                    className='error-icon'
                                    name='exclamation-circle'
                                    title={meta.error}
                                />
                            )}
                        </div>
                    )}
                </Field>
                <div className='submit-wrapper'>
                    <button
                        className='submit-button'
                        type='submit'
                        disabled={
                            loading || hasValidationErrors || submitting || validating
                        }>
                        Σύνδεση
                    </button>
                    {loading && <FontAwesome name='spinner' className='fa-spin' />}
                </div>
            </form>
        ),
        [loading]
    );

    return (
        <div className='login'>
            <Form onSubmit={handleLogin} render={renderForm} />
        </div>
    );
};

export default connect<{}, DispatchProps>(null, dispatch => ({
    onLogin: (username, password) => dispatch<any>(login(username, password))
}))(withRouter(Login));

export const DEFAULT_TITLE = 'Εκδόσεις Βιβλιοδετείο Μποσνάκης';

export const AWS_BASE_URL = 'https://d8lc6s7bpjqw2.cloudfront.net/bosnakis/images';

export const BASE_URL = 'https://personalizedgenericapi.azurewebsites.net/api/Bosnakis';
export const MAIN_BASE_URL =
    'https://personalizedgenericapi.azurewebsites.net/api/Main';

export const CATEGORIES_URL = '/admin/categories';
export const PRODUCTS_URL = '/admin/products';
export const ACCOUNT_URL = '/admin/account';

export const COOKIE_TOKEN_KEY = 'bosnakis_api_token';

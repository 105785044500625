import React from 'react';
import { tns } from 'tiny-slider';
import { AWS_BASE_URL } from './Constants';
import Scroll from './Scroll';

const About: React.FC<{}> = () => {
    React.useEffect(() => {
        tns({
            container: '.carousel',
            items: 2,
            autoplay: true,
            controls: false,
            nav: true,
            navPosition: 'bottom',
            autoplayTimeout: 4500,
            speed: 1000,
            autoplayButtonOutput: false,
            rewind: true,
            mouseDrag: true,
            autoplayResetOnVisibility: false,
            responsive: {
                0: {
                    items: 1
                },
                500: {
                    items: 1
                },
                710: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1199: {
                    items: 3
                }
            }
        });
    }, []);

    const header = (
        <div className='header'>
            <div className='label center'>Εταιρική Ταυτότητα</div>
            <div className='line-scroll center'>
                <span className='line active' />
            </div>
        </div>
    );

    return (
        <Scroll header={header} headerClassName='about' title='Εταιρική Ταυτότητα'>
            <div className='about-wrapper'>
                <div className='top'>
                    <img src={`${AWS_BASE_URL}/about_main_content.png`} />
                    <div className='right'>
                        <div className='page-label'>Το προφίλ μας</div>
                        <div className='page-sub-label'>
                            Παρέχουμε ένα ευρύ φάσμα υπηρεσιών στην έντυπη επικοινωνία
                        </div>
                        <div className='about-text'>
                            <p>
                                Ο Παναγιώτης Μποσνάκης ξεκίνησε τα πρώτα του βήματα στη
                                βιβλιοδεσία, δίπλα στον πατέρα του, το 1982. Στη
                                συνέχεια, εργάστηκε σε διάφορα γνωστά βιβλιοδετεία της
                                Θεσσαλονίκης όπου και απέκτησε πολύτιμη εμπειρία κοντά
                                σε παλιούς μάστορες.
                                <br />
                                <br />
                                To 1994, ξεκινά το δικό του βιβλιοδετείο στην Κάτω
                                Τούμπα της Θεσσαλονίκης. Ειδικεύεται πλέον, εκτός από
                                τις κλασσικές βιβλιοδετικές εργασίες, στην κατασκευή
                                τιμοκαταλόγων, επιτραπέζιων ημερολογίων και διαφόρων
                                ειδικών κατασκευών.
                                <br />
                                <br />
                                Σήμερα, μαζί με τον Ανδρέα -τρίτη γενιά-, έχουν ήδη
                                ξεκινήσει αναδιαρθρώνοντας σε οργανωτικό καθώς και σε
                                επίπεδο υποδομών την μελλοντική πορεία της επιχείρισης.
                                <br />
                                <br />
                                Στις κατηγορίες προϊόντων που ακολουθούν μπορείτε να
                                δείτε μια μικρή μόνο επιλογή από προϊόντα που έχει
                                κατασκευάσει η εταιρεία Μποσνάκης. Περισσότερα δείγματα
                                εργασιών καθώς και τις τεχνικές λεπτομέρειες που είναι
                                απαραίτητες, καλό είναι να επικοινωνήσετε μαζί μας για
                                μια ζωντανή παρουσίαση.
                            </p>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
                <div className='divider' />
                <div className='carousel'>
                    <div className='carousel-item'>
                        <div className='header'>Εκδόσεις</div>
                        <p className='text'>
                            Ο σχεδιασμός, η επιμέλεια και έκδοση βιβλίων είναι μια
                            ιδιαίτερα απαιτητική διαδικασία, η οποία προϋποθέτει
                            εξειδικευμένες γνώσεις, πείρα και μεράκι για το αντικείμενο.
                            <br />
                            Χρειάζεται επίσης και μια «έφεση» και ενδιαφέρον για τα
                            γράμματα και τον πολιτισμό.
                        </p>
                    </div>
                    <div className='carousel-item'>
                        <div className='header'>Προσκλητήρια Γάμου</div>
                        <p className='text'>
                            Μοντέρνα ή κλασικά, μα πάντα χειροποίητα και σε ειδικές
                            κατασκευές, προσκλητήρια γάμου για τη μοναδική ημέρα της
                            ζωής σας.
                        </p>
                    </div>
                    <div className='carousel-item'>
                        <div className='header'>Ειδικές Εκδόσεις</div>
                        <p className='text'>
                            Γκοφρέ, αναγλυφοτυπία, χρυσοτυπία, ασημοτυπία και εφαρμογή
                            ειδικών foil, κοπτικά, καρφίτσα.
                            <br />
                            Μετα-εκτυπωτική εργασία είναι οποιαδήποτε εφαρμογή γίνεται
                            σε ένα έντυπο μετά την εκτύπωσή του. Είναι, διαδικασίες που
                            δίνουν υψηλή προστιθέμενη αξία σε κάθε έντυπο επικοινωνιακό
                            μέσο.
                        </p>
                    </div>
                </div>
            </div>
        </Scroll>
    );
};

export default About;

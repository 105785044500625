import _ from 'lodash';
import classNames from 'classnames';
import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { DEFAULT_TITLE } from './Constants';

const Scroll: React.FC<{
    header: JSX.Element;
    headerClassName?: string;
    title: string;
}> = ({ header, children, headerClassName, title }) => {
    const headerRef = React.useRef<HTMLDivElement>();
    const spinnerRef = React.useRef<HTMLDivElement>();
    const menuRef = React.useRef<HTMLDivElement>();

    const [marginTop, setMarginTop] = React.useState(window.innerHeight);
    const [menuOpen, setMenuOpen] = React.useState(false);

    React.useEffect(() => {
        document.title = `${DEFAULT_TITLE} - ${title}`;
        document.documentElement.scrollTop = 0;

        setTimeout(() => {
            if (!spinnerRef.current) {
                return;
            }

            spinnerRef.current.style.opacity = '0';
            spinnerRef.current.style.display = 'none';
        }, 500);
    }, [title]);

    const handleScroll = React.useCallback(() => {
        if (!headerRef.current) {
            return;
        }

        const offset = window.pageYOffset || document.documentElement.scrollTop;

        if (window.innerWidth > 481) {
            const scale = (100 - offset / 100) / 98;

            headerRef.current.style.opacity = `${Math.max(1 - offset / 20 / 30, 0)}`;
            headerRef.current.style.transform = `scale(${_.clamp(scale, -1, 1)})`;
        } else {
            const scale = (100 - offset / 100) / 99;

            headerRef.current.style.opacity = `${Math.max(1 - offset / 20 / 15, 0)}`;
            headerRef.current.style.transform = `scale(${_.clamp(scale, -1, 1)})`;
        }
    }, []);

    const handleResize = React.useCallback(() => {
        setMarginTop(window.innerHeight);
    }, []);

    const handleMenuClick = React.useCallback(() => {
        setMenuOpen(!menuOpen);
    }, [menuOpen]);

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <>
            <div className='loading' ref={spinnerRef}>
                <div className='ripple'>
                    <div />
                    <div />
                </div>
            </div>
            <div className={classNames('menu', { open: menuOpen })} ref={menuRef}>
                <Link className='link' to='/'>
                    Portfolio
                </Link>
                <Link className='link' to='/about'>
                    Εταιρική Ταυτότητα
                </Link>
                <Link className='link' to='/contact'>
                    Επικοινωνία
                </Link>
                <Link className='link' to='/admin' target='_blank'>
                    -Admin-
                </Link>
                <div className='links'>
                    <a href='mailto:info@bosnakis.gr'>
                        <FontAwesome name='envelope-o' />
                    </a>
                    <a href='mailto:bosnakis@otenet.gr'>
                        <FontAwesome name='envelope-o' />
                    </a>
                    <a
                        href='https://www.facebook.com/%CE%92%CE%B9%CE%B2%CE%BB%CE%B9%CE%BF%CE%B4%CE%B5%CF%84%CE%B5%CE%AF%CE%BF-%CE%9C%CF%80%CE%BF%CF%83%CE%BD%CE%AC%CE%BA%CE%B7-211761432656847'
                        target='_blank'
                        rel='noreferrer'>
                        <FontAwesome name='facebook' />
                    </a>
                </div>
            </div>
            <div className='menu-icon-wrapper'>
                <div
                    className={classNames('menu-icon', { open: menuOpen })}
                    onClick={handleMenuClick}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div
                ref={headerRef}
                className={classNames('header-wrapper', headerClassName)}>
                {header}
            </div>
            <div className='content-wrapper'>
                <div style={{ marginTop, position: 'relative' }}>{children}</div>
                <div className='footer'>
                    <div className='left'>
                        <div className='hover-elem'>6932130592 / 6973043294</div>
                        <div className='hover-elem'>2310935369</div>
                        <a href='mailto:info@bosnakis.gr' className='hover-elem'>
                            info@bosnakis.gr
                        </a>
                        <a href='mailto:bosnakis@otenet.gr' className='hover-elem'>
                            bosnakis@otenet.gr
                        </a>
                        <a
                            href='https://www.facebook.com/%CE%92%CE%B9%CE%B2%CE%BB%CE%B9%CE%BF%CE%B4%CE%B5%CF%84%CE%B5%CE%AF%CE%BF-%CE%9C%CF%80%CE%BF%CF%83%CE%BD%CE%AC%CE%BA%CE%B7-211761432656847'
                            target='_blank'
                            rel='noreferrer'>
                            Facebook
                        </a>
                    </div>
                    <div className='right'>
                        © Bosnakis 2021. All right reserved. Developed by
                        <a
                            href='https://personalized.at'
                            target='_blank'
                            rel='noreferrer'
                            className='personalized'>
                            Personalized
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Scroll;

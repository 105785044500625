import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Form, Field, FormRenderProps } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import FontAwesomeIcon from 'react-fontawesome';
import { AppStore, INotification, Store } from './types';
import { enqueueNotification } from './actions/Actions';
import { deleteCookieAndRedirect, required } from './helpers';
import * as Api from './Api';

interface StateProps {
    user: AppStore['user'];
}

interface DispatchProps {
    onEnqueueNotification: (notification: INotification) => void;
}

type FormValues = Pick<AppStore['user'], 'userName'> & {
    password: string;
    repeatPassword: string;
};

const Account: React.FC<StateProps & DispatchProps> = ({
    user,
    onEnqueueNotification
}) => {
    const [loading, setLoading] = React.useState(false);

    const validateForm = React.useCallback((values: FormValues) => {
        const errors: ValidationErrors = {};

        if (values.password !== values.repeatPassword) {
            errors.repeatPassword = 'Οι κωδικοί δεν ταιριάζουν';
        }

        return errors;
    }, []);

    const renderForm = React.useCallback(
        ({
            handleSubmit,
            submitting,
            validating,
            hasValidationErrors
        }: FormRenderProps) => (
            <form onSubmit={handleSubmit} className='form'>
                <label className='form-field'>
                    <label>USERNAME:</label>
                    <Field name='userName' type='text'>
                        {({ input }) => (
                            <div className='field-input'>
                                <input {...input} disabled />
                            </div>
                        )}
                    </Field>
                </label>
                <label className='form-field'>
                    <label>ΚΩΔΙΚΟΣ:</label>
                    <Field name='password' type='password' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} autoComplete='new-password' />
                                {meta.error && meta.touched && (
                                    <FontAwesomeIcon
                                        className='error-icon'
                                        name='exclamation-circle'
                                        title={meta.error}
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </label>
                <label className='form-field'>
                    <label>ΕΠΙΒΕΒΑΙΩΣΗ ΚΩΔΙΚΟΥ:</label>
                    <Field name='repeatPassword' type='password'>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} autoComplete='new-password' />
                                {meta.error && meta.touched && (
                                    <FontAwesomeIcon
                                        className='error-icon'
                                        name='exclamation-circle'
                                        title={meta.error}
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </label>
                <div className='action-buttons'>
                    <div>
                        <button
                            className='save'
                            type='submit'
                            disabled={
                                loading ||
                                submitting ||
                                validating ||
                                hasValidationErrors
                            }>
                            ΑΠΟΘΗΚΕΥΣΗ
                        </button>
                        {loading && (
                            <FontAwesomeIcon name='spinner' className='fa-spin' />
                        )}
                    </div>
                </div>
            </form>
        ),
        [loading]
    );

    const handleSubmit = React.useCallback(
        (values: FormValues) => {
            setLoading(true);

            return Api.resetPassword(values)
                .then(res => {
                    if (res.status === 200) {
                        return res.text();
                    }

                    throw new Error('Κάτι πήγε στραβά με το αίτημά σας');
                })
                .then(() => {
                    onEnqueueNotification({
                        key: new Date().getTime(),
                        message: 'Ο κωδικός σας άλλαξε επιτυχώς',
                        options: { variant: 'success' }
                    });

                    deleteCookieAndRedirect();
                })
                .catch((e: Error) => {
                    setLoading(false);
                    onEnqueueNotification({
                        key: new Date().getTime(),
                        message: e.message,
                        options: { variant: 'error' }
                    });
                });
        },
        [onEnqueueNotification]
    );

    return (
        <>
            <div className='sidebar' />
            <div className='form-wrapper'>
                <Form
                    initialValues={{ ..._.pick(user, 'userName') }}
                    validate={validateForm}
                    render={renderForm}
                    onSubmit={handleSubmit}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => ({
    user: state.app.user
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    onEnqueueNotification: notification => dispatch(enqueueNotification(notification))
});

export default connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(Account);

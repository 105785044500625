import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { FormApi, ValidationErrors } from 'final-form';
import { Field, Form, FormRenderProps } from 'react-final-form';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { AppStore, DeleteType, INotification } from './types';
import * as Api from './Api';
import { enqueueNotification } from './actions/Actions';
import DeleteButton from './DeleteButton';

interface DispatchProps {
    onEnqueueNotification: (notification: INotification) => void;
}

type Category = AppStore['categories'][number] & {
    parentCategoryName?: string;
};

const removeCategory = (
    categories: AppStore['categories'],
    catId: number
): AppStore['categories'] =>
    categories
        .filter(({ id }) => id !== catId)
        .map(cat => ({
            ...cat,
            subCategories: removeCategory(cat.subCategories, catId)
        }));

const addCategory = (
    categories: AppStore['categories'],
    category: Category
): AppStore['categories'] =>
    _.isNil(category.parentCategoryID)
        ? categories.concat(category)
        : categories.map(cat => ({
              ...cat,
              subCategories:
                  cat.id === category.parentCategoryID
                      ? cat.subCategories.concat(category)
                      : addCategory(cat.subCategories, category)
          }));

const updateCategory = (
    categories: AppStore['categories'],
    category: Category
): AppStore['categories'] =>
    categories.map(cat =>
        cat.id === category.id
            ? { ...cat, ...category }
            : {
                  ...cat,
                  subCategories: updateCategory(cat.subCategories, category)
              }
    );

const Categories: React.FC<
    {
        categories: AppStore['categories'];
        onCategoriesUpdate: (categories: AppStore['categories']) => void;
    } & DispatchProps
> = ({ categories, onCategoriesUpdate, onEnqueueNotification }) => {
    const [selectedCategory, setSelectedCategory] = React.useState<Category>(null);
    const [loading, setLoading] = React.useState(false);

    const handleCategoryClick = React.useCallback(
        (category: Category) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            setSelectedCategory(category);
        },
        []
    );

    const getCategories = (
        cat: AppStore['categories'],
        isSubCategory = false,
        parentCategoryName: string = ''
    ): JSX.Element[] =>
        cat.map(category => (
            <div
                key={category.id}
                className={classNames('category', category.id, {
                    'sub-category': isSubCategory,
                    selected: category.id === selectedCategory?.id
                })}
                onClick={handleCategoryClick({
                    ...category,
                    parentCategoryName
                })}>
                <div className='category-title'>
                    <div className='left'>{category.title}</div>
                    <div className='right'>
                        <FontAwesome
                            name='plus'
                            title='Νέα υποκατηγορία'
                            className='add-sub'
                            onClick={handleCategoryClick({
                                parentCategoryID: category.id,
                                parentCategoryName: category.title
                            } as Category)}
                        />
                    </div>
                </div>
                {!!category.subCategories.length &&
                    getCategories(category.subCategories, true, category.title)}
            </div>
        ));

    const resetFormAndFields = React.useCallback((form: FormApi<Category>) => {
        setSelectedCategory(null);
        form.reset({});

        ['title', 'internalName'].forEach((key: keyof Category) => {
            form.resetFieldState(key);
        });
    }, []);

    const handleCategoryDelete = React.useCallback(
        (id: number, form: FormApi<Category>) => () => {
            setLoading(true);

            Api.deleteEntity(id, DeleteType.CATEGORY)
                .then(() => {
                    setLoading(false);

                    onCategoriesUpdate(removeCategory(categories, id));
                    resetFormAndFields(form);
                })
                .catch((e: Error) => {
                    onEnqueueNotification({
                        key: new Date().getTime(),
                        message: e.message,
                        options: {
                            variant: 'error',
                            autoHideDuration: 5000
                        }
                    });
                });
        },
        [onCategoriesUpdate, categories, resetFormAndFields, onEnqueueNotification]
    );

    const handleCategoryUpdate = React.useCallback(
        (values: Category, form: FormApi<Category>) => {
            setLoading(true);

            Api.addUpdateCategory(
                _.omit(values, 'parentCategoryName', 'products', 'subCategories')
            )
                .then((category: Category) => {
                    setLoading(false);

                    onCategoriesUpdate(
                        _.isNil(values.id)
                            ? addCategory(categories, category)
                            : updateCategory(categories, category)
                    );

                    resetFormAndFields(form);
                })
                .catch((e: Error) => {
                    onEnqueueNotification({
                        key: new Date().getTime(),
                        message: e.message,
                        options: {
                            variant: 'error',
                            autoHideDuration: 5000
                        }
                    });
                });
        },
        [onCategoriesUpdate, categories, resetFormAndFields, onEnqueueNotification]
    );

    const renderForm = React.useCallback(
        ({
            validating,
            hasValidationErrors,
            hasSubmitErrors,
            handleSubmit,
            values,
            form
        }: FormRenderProps<Category>) => (
            <form className='form' onSubmit={handleSubmit}>
                <label className='form-field'>
                    <label>ΤΙΤΛΟΣ:</label>
                    <Field name='title'>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} />
                                {meta.error && meta.touched && (
                                    <FontAwesome
                                        className='error-icon'
                                        name='exclamation-circle'
                                        title={meta.error}
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </label>
                <label className='form-field'>
                    <label>INTERNAL NAME:</label>
                    <Field name='internalName'>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} disabled={!_.isNil(values.id)} />
                                {meta.error && meta.touched && (
                                    <FontAwesome
                                        className='error-icon'
                                        name='exclamation-circle'
                                        title={meta.error}
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </label>
                <label className='form-field'>
                    <label>ΚΑΤΗΓΟΡΙΑ:</label>
                    <Field name='parentCategoryName'>
                        {({ input }) => (
                            <div className='field-input'>
                                <input {...input} disabled />
                            </div>
                        )}
                    </Field>
                </label>
                <div className='action-buttons'>
                    <button
                        type='submit'
                        className='save'
                        disabled={
                            loading ||
                            validating ||
                            hasValidationErrors ||
                            hasSubmitErrors
                        }>
                        ΑΠΟΘΗΚΕΥΣΗ
                    </button>
                    <DeleteButton
                        title='Διαγραφή κατηγορίας'
                        message={
                            `Είστε σίγουροι ότι θέλετε να διαγράψετε την κατηγορία "${values.id} - ${values.title}";` +
                            '<br/>Όλες οι υποκατηγορίες και τα προϊόντα αυτής θα διαγραφούν επίσης! ' +
                            'Η ενέργεια αυτή είναι μη αντιστρέψιμη!'
                        }
                        disabled={
                            loading ||
                            _.isNil(values.id) ||
                            validating ||
                            hasValidationErrors ||
                            hasSubmitErrors
                        }
                        onAccept={handleCategoryDelete(values.id, form)}
                    />
                    {loading && <FontAwesome name='spinner' className='fa-spin' />}
                </div>
            </form>
        ),
        [handleCategoryDelete, loading]
    );

    const validateForm = React.useCallback((values: Category) => {
        const errors: ValidationErrors = {};

        if (!values.title) {
            errors.title = 'Υποχρεωτικό πεδίο';
        }
        if (!values.internalName) {
            errors.internalName = 'Υποχρεωτικό πεδίο';
        }

        if (!/^\S*$/.test(values.internalName)) {
            errors.internalName = 'Το πεδίο δεν μπορεί να περιέχει κενά';
        }
        if (!/^[a-zA-Z0-9_]*$/.test(values.internalName)) {
            errors.internalName =
                'Το πεδίο μπορεί να περιέχει μόνο λατινικούς χαρακτήρες, νούμερα και _';
        }

        return errors;
    }, []);

    return (
        <>
            <div className='categories sidebar'>
                <FontAwesome
                    name='plus'
                    title='Νέα κατηγορία'
                    className='add'
                    onClick={handleCategoryClick({
                        parentCategoryID: null
                    } as Category)}
                />
                {getCategories(categories)}
            </div>
            <div className='form-wrapper'>
                <Form
                    render={renderForm}
                    onSubmit={handleCategoryUpdate}
                    initialValues={{ ...selectedCategory }}
                    validate={validateForm}
                />
            </div>
        </>
    );
};

export default connect<{}, DispatchProps>(
    null,
    (dispatch): DispatchProps => ({
        onEnqueueNotification: notification =>
            dispatch(enqueueNotification(notification))
    })
)(Categories);

import { SnackbarKey } from 'notistack';
import { AppStore, INotification } from '../types';
import { Action, ActionType, ThunkAction } from './actionTypes';
import * as Api from '../Api';

const ERROR_NOTIFICATION: INotification = {
    message: 'Κάτι πήγε στραβά με το αίτημά σας',
    key: new Date().getTime(),
    options: {
        variant: 'error',
        autoHideDuration: 3000
    }
};

export const removeNotification = (key: SnackbarKey): Action => ({
    type: ActionType.REMOVE_NOTIFICATION,
    payload: key
});

export const enqueueNotification = (notification: INotification): Action => ({
    type: ActionType.ENQUEUE_NOTIFICATION,
    payload: notification
});

export const setCategories = (categories: AppStore['categories']): Action => ({
    type: ActionType.SET_CATEGORIES,
    payload: categories
});

const setUser = (user: AppStore['user']): Action => ({
    type: ActionType.SET_USER,
    payload: user
});

export const fetchAll =
    (withToken: boolean = true): ThunkAction<Promise<void>> =>
    (dispatch, getState) => {
        const { categories, user } = getState().app;

        const promises: any[] = [
            categories ? Promise.resolve(categories) : Api.getCategories()
        ];

        if (withToken) {
            promises.push(user ? Promise.resolve(user) : Api.getUserAccount());
        }

        return Promise.all(promises)
            .then(([c, u]) => {
                dispatch(setCategories(c));

                if (withToken) {
                    dispatch(setUser(u));
                }
            })
            .catch(() => {
                dispatch(enqueueNotification(ERROR_NOTIFICATION));
            });
    };

export const login =
    (username: string, password: string): ThunkAction<Promise<void>> =>
    async dispatch => {
        try {
            await Api.login(username, password);
        } catch (_e) {
            dispatch(enqueueNotification(ERROR_NOTIFICATION));
            return null;
        }

        return dispatch(fetchAll());
    };

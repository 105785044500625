import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import FontAwesome from 'react-fontawesome';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { ARRAY_ERROR, FormApi, ValidationErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Box, Fade, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { connect } from 'react-redux';
import { AppStore, DeleteType, INotification, Product, Category } from './types';
import * as Api from './Api';
import { enqueueNotification } from './actions/Actions';
import DeleteButton from './DeleteButton';
import { required } from './helpers';

const SPECIAL_FIELD = 'special_file_input_field';
const FILE_SIZE_LIMIT = 3e6; // 3MB

type IProduct = Omit<Product, 'productGalleryImages'> & {
    parentCategoryName?: string;
    productGalleryImages: (Product['productGalleryImages'][number] & { file?: File })[];
    [SPECIAL_FIELD]?: unknown;
};

interface DispatchProps {
    onEnqueueNotification: (notification: INotification) => void;
}

type AllCategories = { id: number; name: string }[];
const getAllCategories = (
    categories: AppStore['categories'],
    allCategories: AllCategories = []
): AllCategories =>
    categories.reduce(
        (acc, { id, title, subCategories }) =>
            acc.concat(getAllCategories(subCategories, [{ id, name: title }])),
        allCategories
    );

const removeProduct = (
    categories: AppStore['categories'],
    productId: number
): AppStore['categories'] =>
    categories.map(cat => ({
        ...cat,
        products: cat.products.filter(({ id }) => id !== productId),
        subCategories: removeProduct(cat.subCategories, productId)
    }));

const addProduct = (
    categories: AppStore['categories'],
    product: Product
): AppStore['categories'] =>
    categories.map(cat => ({
        ...cat,
        products:
            cat.id === product.productCategoryID
                ? cat.products.concat(product)
                : cat.products,
        subCategories: addProduct(cat.subCategories, product)
    }));

const updateProduct = (
    categories: AppStore['categories'],
    product: Product
): AppStore['categories'] =>
    categories.map(cat => ({
        ...cat,
        products:
            cat.id === product.productCategoryID
                ? cat.products.map(p =>
                      p.id === product.id ? { ...p, ...product } : p
                  )
                : cat.products,
        subCategories: updateProduct(cat.subCategories, product)
    }));

const getBase64Image = (file: File, cb: (base64?: string) => void): void => {
    if (!file) {
        cb();
        return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
        cb(fileReader.result as string);
    };
};

const getProductTitle = ({ title, id }: Product): string =>
    title || `${id} - Χωρίς τίτλο`;

type ProductClick = (
    p: IProduct
) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

const getProductsAndCategories = (
    cat: AppStore['categories'],
    selectedProduct: IProduct,
    onClickListener: ProductClick,
    isSubCategory = false
): JSX.Element[] =>
    cat.map(category => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ExpandableCategory
            key={category.id}
            category={category}
            isSubCategory={isSubCategory}
            selectedProduct={selectedProduct}
            onProductSelect={onClickListener}
        />
    ));

const ExpandableCategory: React.FC<{
    category: Category;
    isSubCategory: boolean;
    selectedProduct: IProduct;
    onProductSelect: ProductClick;
}> = ({ category, onProductSelect, selectedProduct, isSubCategory }) => {
    const [expanded, setExpanded] = React.useState(false);
    const { id, title, products, subCategories } = category;

    const toggleExpanded = React.useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
            setExpanded(!expanded);
        },
        [expanded]
    );

    return (
        <div
            key={id}
            className={classNames('category', {
                'sub-category': isSubCategory
            })}>
            <div
                className='category-title'
                onClick={onProductSelect({
                    productCategoryID: id,
                    parentCategoryName: title
                } as IProduct)}>
                <div className='left'>
                    <FontAwesome
                        name={expanded ? 'angle-up' : 'angle-down'}
                        className='toggleIcon'
                        onClick={toggleExpanded}
                    />
                    {title}
                </div>
                <div className='right'>
                    <FontAwesome name='plus' title='Νέο προϊόν' className='add-sub' />
                </div>
            </div>
            <Box>
                <Fade in={expanded} timeout={300}>
                    <div>
                        {expanded &&
                            products.map(product => (
                                <div
                                    key={`product_${product.id}`}
                                    className={classNames('product', {
                                        selected: product.id === selectedProduct?.id
                                    })}
                                    onClick={onProductSelect({
                                        ...product,
                                        parentCategoryName: title
                                    })}>
                                    {getProductTitle(product)}
                                </div>
                            ))}
                        {expanded &&
                            getProductsAndCategories(
                                subCategories,
                                selectedProduct,
                                onProductSelect,
                                true
                            )}
                    </div>
                </Fade>
            </Box>
        </div>
    );
};

const canDeleteImage = (images: IProduct['productGalleryImages']): boolean =>
    images.filter(x => !_.isNil(x?.id)).length > 1;

const Products: React.FC<
    DispatchProps & {
        categories: AppStore['categories'];
        onCategoriesUpdate: (categories: AppStore['categories']) => void;
    }
> = ({ categories, onCategoriesUpdate, onEnqueueNotification }) => {
    const [selectedProduct, setSelectedProduct] = React.useState<IProduct>(null);
    const [loading, setLoading] = React.useState(false);
    const fileInput = React.useRef<HTMLInputElement>();

    const handleProductClick = React.useCallback(
        (product: IProduct) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            setSelectedProduct(product);

            if (fileInput.current) {
                fileInput.current.value = '';
            }
        },
        []
    );

    const resetFormAndFields = React.useCallback((form: FormApi<IProduct>) => {
        form.reset({});

        fileInput.current.value = '';
        setSelectedProduct(null);

        [
            'title',
            'description',
            'price',
            'productCategoryID',
            'productGalleryImages'
        ].forEach((key: keyof IProduct) => {
            form.resetFieldState(key);
        });
    }, []);

    const handleProductDelete = React.useCallback(
        (product: IProduct, form: FormApi<IProduct>) => () => {
            setLoading(true);

            Api.deleteEntity(product.id, DeleteType.PRODUCT)
                .then(() => {
                    setLoading(false);

                    onCategoriesUpdate(removeProduct(categories, product.id));
                    resetFormAndFields(form);

                    onEnqueueNotification({
                        key: new Date().getTime(),
                        message: `Το προϊόν "${getProductTitle(
                            product
                        )}" διαγράφηκε επιτυχώς`,
                        options: {
                            variant: 'success'
                        }
                    });
                })
                .catch((e: Error) => {
                    onEnqueueNotification({
                        key: new Date().getTime(),
                        message: e.message,
                        options: {
                            variant: 'error',
                            autoHideDuration: 5000
                        }
                    });
                });
        },
        [categories, onCategoriesUpdate, resetFormAndFields, onEnqueueNotification]
    );

    const handleImageDelete = React.useCallback(
        (
                { id, name }: IProduct['productGalleryImages'][number],
                form: FormApi<IProduct>,
                product: IProduct,
                arrayIdx: number
            ) =>
            () => {
                setLoading(true);

                Api.deleteEntity(id, DeleteType.IMAGE)
                    .then(() => {
                        setLoading(false);

                        onCategoriesUpdate(
                            updateProduct(categories, {
                                ...product,
                                productGalleryImages: (
                                    product.productGalleryImages || []
                                ).filter((_x, i) => i !== arrayIdx)
                            })
                        );

                        form.mutators.remove('productGalleryImages', arrayIdx);

                        onEnqueueNotification({
                            key: new Date().getTime(),
                            message: `Η εικόνα "${id} - ${name}" διαγράφηκε επιτυχώς`,
                            options: {
                                variant: 'success'
                            }
                        });
                    })
                    .catch((e: Error) => {
                        onEnqueueNotification({
                            key: new Date().getTime(),
                            message: e.message,
                            options: {
                                variant: 'error',
                                autoHideDuration: 5000
                            }
                        });
                    });
            },
        [onEnqueueNotification, onCategoriesUpdate, categories]
    );

    const renderFileInput = React.useCallback(
        (ref?: React.MutableRefObject<HTMLInputElement>, cb?: (file: File) => void) => (
            <Field name={SPECIAL_FIELD}>
                {({ input }) => (
                    <input
                        type='file'
                        ref={ref}
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={e => {
                            // eslint-disable-next-line prefer-destructuring
                            const file = e.target.files[0];

                            if (!file) {
                                return;
                            }

                            if (file.size <= FILE_SIZE_LIMIT) {
                                cb?.(file);
                                input.onChange(e.target.files);
                                return;
                            }

                            onEnqueueNotification({
                                key: new Date().getTime(),
                                message: `Το αρχείο ${file.name} ξεπερνάει το όριου μεγέθους (3MB)`,
                                options: {
                                    variant: 'error',
                                    autoHideDuration: 5000
                                }
                            });
                            e.target.value = '';
                        }}
                        accept='.jpg, .png, .jpeg'
                    />
                )}
            </Field>
        ),
        [onEnqueueNotification]
    );

    const renderForm = React.useCallback(
        ({
            handleSubmit,
            form,
            validating,
            hasValidationErrors,
            hasSubmitErrors,
            values
        }: FormRenderProps<IProduct>) => (
            <form className='form' onSubmit={handleSubmit}>
                <label className='form-field'>
                    <label>ΤΙΤΛΟΣ:</label>
                    <Field name='title' component='input' type='text' />
                </label>
                <label className='form-field'>
                    <label>ΠΕΡΙΓΡΑΦΗ:</label>
                    <Field name='description' component='textarea' />
                </label>
                <label className='form-field'>
                    <label>ΤΙΜΗ:</label>
                    <Field
                        name='price'
                        component='input'
                        type='number'
                        min='0'
                        step='0.01'
                    />
                </label>
                <label className='form-field'>
                    <label>ΚΑΤΗΓΟΡΙΑ:</label>
                    <Field name='productCategoryID' validate={required}>
                        {({ input, meta }) => (
                            <div className='category-id'>
                                <select {...input}>
                                    <option />
                                    {getAllCategories(categories).map(
                                        ({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        )
                                    )}
                                </select>
                                {meta.error && meta.touched && (
                                    <FontAwesome
                                        className='error-icon'
                                        name='exclamation-circle'
                                        title={meta.error}
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </label>
                <label className='form-field'>
                    <label>ΕΙΚΟΝΑ:</label>
                    {!_.isNil(values.id) ? (
                        <Field name='imageName' validate={required}>
                            {({ input }) => (
                                <div className='field-input'>
                                    <input {...input} disabled />
                                </div>
                            )}
                        </Field>
                    ) : (
                        renderFileInput(fileInput)
                    )}
                </label>
                <div>
                    <label className='form-field no-margin'>
                        <label>ΕΙΚΟΝΕΣ GALLERY:</label>
                    </label>
                    <FontAwesome
                        name='plus'
                        className='icon'
                        title='Νέα εικόνα'
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() =>
                            form.mutators.push('productGalleryImages', undefined)
                        }
                    />
                    <FieldArray name='productGalleryImages'>
                        {({ fields }) => (
                            <Table className='product-images'>
                                <TableBody>
                                    {fields.map((name, idx) => (
                                        <TableRow key={name} className='product-image'>
                                            <TableCell>
                                                {_.isNil(fields.value[idx]?.id) ? (
                                                    <FontAwesome
                                                        className='icon'
                                                        name='remove'
                                                        title='Διαγραφή εικόνας'
                                                        // eslint-disable-next-line react/jsx-no-bind
                                                        onClick={() => {
                                                            fields.remove(idx);
                                                        }}
                                                    />
                                                ) : (
                                                    <DeleteButton
                                                        icon='remove'
                                                        iconTitle='Διαγραφή εικόνας'
                                                        onAccept={handleImageDelete(
                                                            fields.value[idx],
                                                            form,
                                                            values,
                                                            idx
                                                        )}
                                                        disabled={
                                                            !canDeleteImage(
                                                                fields.value
                                                            )
                                                        }
                                                        title='Διαγραφή εικόνας gallery'
                                                        message={
                                                            'Είστε σίγουροι ότι θέλετε να διαγράψετε την εικόνα gallery ' +
                                                            `"${fields.value[idx]?.id} - ${fields.value[idx]?.name}"; ` +
                                                            'Η ενέργεια αυτή είναι μη αντιστρέψιμη!'
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <div className='form-field'>
                                                    {_.isNil(fields?.value[idx]?.id) ? (
                                                        renderFileInput(
                                                            undefined,
                                                            file => {
                                                                getBase64Image(
                                                                    file,
                                                                    imageSourceBase64 => {
                                                                        fields.update(
                                                                            idx,
                                                                            {
                                                                                ...fields
                                                                                    .value[
                                                                                    idx
                                                                                ],
                                                                                imageSourceBase64,
                                                                                name: file.name
                                                                            }
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        )
                                                    ) : (
                                                        <Field
                                                            name={`${name}.name`}
                                                            component='input'
                                                            type='text'
                                                            placeholder='Τίτλος'
                                                            disabled
                                                        />
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className='form-field'>
                                                    <Field
                                                        name={`${name}.description`}
                                                        component='textarea'
                                                        placeholder='Περιγραφή'
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </FieldArray>
                </div>
                <div className='action-buttons'>
                    <button
                        type='submit'
                        className='save'
                        disabled={
                            loading ||
                            validating ||
                            hasValidationErrors ||
                            hasSubmitErrors
                        }>
                        ΑΠΟΘΗΚΕΥΣΗ
                    </button>
                    <DeleteButton
                        title='Διαγραφή προϊόντος'
                        message={`Είστε σίγουροι ότι θέλετε να διαγράψετε το προϊόν "${getProductTitle(
                            values
                        )}"; Η ενέργεια αυτή είναι μη αντιστρέψιμη!`}
                        onAccept={handleProductDelete(values, form)}
                        disabled={
                            loading ||
                            _.isNil(values.id) ||
                            validating ||
                            hasValidationErrors ||
                            hasSubmitErrors
                        }
                    />
                    {loading && <FontAwesome name='spinner' className='fa-spin' />}
                </div>
            </form>
        ),
        [handleProductDelete, renderFileInput, loading, categories, handleImageDelete]
    );

    const getSelectedImage = React.useCallback(() => fileInput.current?.files[0], []);

    const validateForm = React.useCallback(
        (values: IProduct) => {
            const errors: ValidationErrors = {};

            if ((values.productGalleryImages || []).filter(i => !i?.name).length) {
                errors[ARRAY_ERROR] = 'Πρέπει να επιλέξετε αρχείο εικόνας';
            }

            if (_.isNil(values.id) && !getSelectedImage()) {
                errors.imageName = 'Πρέπει να επιλέξετε αρχείο εικόνας';
            }

            return errors;
        },
        [getSelectedImage]
    );

    const handleProductUpdate = React.useCallback(
        (values: IProduct, form: FormApi<IProduct>) => {
            const selectedImage = getSelectedImage();

            setLoading(true);
            getBase64Image(selectedImage, imageSourceBase64 => {
                const updatedProduct: IProduct = {
                    ..._.omit(values, 'parentCategoryName', SPECIAL_FIELD),
                    ...(values.price && { price: Number(values.price) }),
                    imageName: selectedImage?.name || values.imageName,
                    imageSourceBase64
                };

                return Api.addUpdateProduct(updatedProduct)
                    .then((product: Product) => {
                        setLoading(false);
                        const isNew = _.isNil(values.id);

                        onCategoriesUpdate(
                            isNew
                                ? addProduct(categories, product)
                                : updateProduct(categories, product)
                        );

                        onEnqueueNotification({
                            key: new Date().getTime(),
                            message: `Το προϊόν "${getProductTitle(product)}" ${
                                isNew ? 'προστέθηκε' : 'ανανεώθηκε'
                            } επιτυχώς`,
                            options: {
                                variant: 'success'
                            }
                        });

                        resetFormAndFields(form);
                    })
                    .catch((e: Error) => {
                        onEnqueueNotification({
                            key: new Date().getTime(),
                            message: e.message,
                            options: {
                                variant: 'error',
                                autoHideDuration: 5000
                            }
                        });
                    });
            });
        },
        [
            resetFormAndFields,
            categories,
            onCategoriesUpdate,
            onEnqueueNotification,
            getSelectedImage
        ]
    );

    return (
        <>
            <div className='products sidebar'>
                {getProductsAndCategories(
                    categories,
                    selectedProduct,
                    handleProductClick
                )}
            </div>
            <div className='form-wrapper'>
                <Form
                    render={renderForm}
                    onSubmit={handleProductUpdate}
                    initialValues={{ ...selectedProduct }}
                    validate={validateForm}
                    mutators={{ ...arrayMutators }}
                />
            </div>
        </>
    );
};

export default connect<{}, DispatchProps>(
    null,
    (dispatch): DispatchProps => ({
        onEnqueueNotification: notification =>
            dispatch(enqueueNotification(notification))
    })
)(Products);

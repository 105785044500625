import ReactDOM from 'react-dom';
import { History } from 'history';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {
    ConnectedRouter,
    connectRouter,
    routerMiddleware
} from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Reducers from './reducers/Reducers';
import STORE from './store';
import Notifier from './Notifier';
import Contact from './Contact';
import About from './About';
import Portfolio from './Portfolio';
import Admin from './Admin';
import Login from './Login';
import AuthorizedRoute from './AuthorizedRoute';
import history from './history';

import './styles/main.less';

const middlewares: any[] = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
        collapsed: true,
        titleFormatter: action => `${action.type}`
    });
    middlewares.push(logger);
}

const createRootReducer = (h: History) =>
    combineReducers({
        router: connectRouter(h),
        app: Reducers
    });

const configureStore = (initialState = STORE) =>
    createStore(
        createRootReducer(history),
        initialState,
        applyMiddleware(...middlewares)
    );

ReactDOM.render(
    <Provider store={configureStore()}>
        <ConnectedRouter history={history}>
            <SnackbarProvider
                maxSnack={4}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                hideIconVariant>
                <Notifier />
                <Switch>
                    <Route exact path='/' component={Portfolio} />
                    <Route exact path='/about' component={About} />
                    <Route exact path='/contact' component={Contact} />
                    <Route exact path='/login' component={Login} />
                    <AuthorizedRoute path='/admin/:id?' component={Admin} />
                    <Redirect to='/' />
                </Switch>
            </SnackbarProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

import { BASE_URL, COOKIE_TOKEN_KEY, MAIN_BASE_URL } from './Constants';
import { deleteCookieAndRedirect, getTokenFromCookies } from './helpers';
import { AppStore, DeleteType, User } from './types';

const checkIfAuthorized = (res: Response) => {
    // unauthorized
    if (res.status === 401) {
        deleteCookieAndRedirect();
        throw Error('Η σύνδεσή σας έληξε. Παρακαλώ συνδεθείτε ξανά');
    }
};

const postWithToken = (url: string, data: Record<string, unknown>) =>
    fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${getTokenFromCookies()}`,
            'Content-Type': 'application/json'
        }
    }).then(res => {
        checkIfAuthorized(res);

        if (res.status === 403) {
            throw new Error('Δεν επιτρέπεται να εκτελέσετε αυτήν την ενέργεια');
        }

        return res;
    });

const getWithToken = (url: string) =>
    fetch(url, {
        headers: {
            Authorization: `Bearer ${getTokenFromCookies()}`
        }
    }).then(res => {
        checkIfAuthorized(res);

        if (res.status === 200) {
            return res.json();
        }

        return res.text();
    });

export const login = (username: string, password: string) =>
    fetch(`${MAIN_BASE_URL}/Login`, {
        method: 'POST',
        body: JSON.stringify({
            username,
            password,
            application: 2
        }),
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => {
            checkIfAuthorized(res);

            if (res.status === 200) {
                return res.json();
            }

            throw Error('Παρακαλώ ελέγξτε τα στοιχεία σας και προσπαθήστε ξανά');
        })
        .then(({ token }: { token: string }) => {
            document.cookie = `${COOKIE_TOKEN_KEY}=${token}`;
        });

export const getCategories = (): Promise<AppStore['categories']> =>
    fetch(`${BASE_URL}/GetCategories`).then(res => res.json());

export const contact = (values: Record<string, any>) =>
    fetch(`${BASE_URL}/ContactUs`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json'
        }
    });

export const addUpdateCategory = (values: Record<string, any>) =>
    postWithToken(`${BASE_URL}/AddUpdateProductCategory`, values).then(res =>
        res.json()
    );

export const addUpdateProduct = (values: Record<string, any>) =>
    postWithToken(`${BASE_URL}/AddUpdateProduct`, values).then(res => res.json());

export const deleteEntity = (entityID: number, type: DeleteType) =>
    postWithToken(`${BASE_URL}/DeleteEntity`, { entityID, type });

export const getUserAccount = (): Promise<User> =>
    getWithToken(`${MAIN_BASE_URL}/GetUserDetails`);

export const resetPassword = data =>
    postWithToken(`${MAIN_BASE_URL}/ResetOwnPassword`, data);

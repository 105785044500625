import { RouterState } from 'connected-react-router';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

export interface User {
    accessFailedCount: number;
    concurrencyStamp: any;
    email: string;
    emailConfirmed: string;
    fullName: string;
    id: string;
    lockoutEnabled: boolean;
    lockoutEnd: boolean;
    normalizedEmail: boolean;
    normalizedUserName: boolean;
    password: string;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    securityStamp: string;
    store: string;
    storeID: number;
    twoFactorEnabled: boolean;
    userName: string;
    timestamp?: string;
}

export interface AppStore {
    notifications: INotification[];
    categories: Category[];
    user: User;
}

export interface Store {
    router?: RouterState;
    app: AppStore;
}

export interface INotification {
    message: SnackbarMessage;
    options: OptionsObject;
    key: SnackbarKey;
}

export interface Product {
    id: number;
    title: string;
    description: string;
    price: number;
    imageName: string;
    productGalleryImages: {
        id: number;
        name: string;
        description: string;
        imageSourceBase64?: string;
    }[];
    productCategoryID: number;
    imageSourceBase64?: string;
}

export interface Category {
    id: number;
    index?: number;
    title: string;
    internalName: string;
    subCategories: Category[];
    products: Product[];
    parentCategoryID?: number;
}

export enum DeleteType {
    CATEGORY = 1,
    PRODUCT = 2,
    IMAGE = 3
}

import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { AWS_BASE_URL } from './Constants';
import Scroll from './Scroll';
import { AppStore, Product, Store } from './types';
import { fetchAll } from './actions/Actions';

interface StateProps {
    categories: AppStore['categories'];
}

interface DispatchProps {
    onFetchData: () => Promise<void>;
}

const ALL_ID = -1;

type ExtendedProduct = Product & {
    categoryPath?: string;
    category?: string;
};

export const getAllProducts = (
    categories: StateProps['categories'],
    allProducts: Product[] = []
): ExtendedProduct[] =>
    categories.reduce(
        (acc, { products, internalName, subCategories, title }) =>
            acc.concat(
                getAllProducts(
                    subCategories,
                    products.map(p => ({
                        ...p,
                        category: title,
                        categoryPath: internalName
                    }))
                )
            ),
        allProducts as ExtendedProduct[]
    );

const getImageUrl = (
    { categoryPath, productGalleryImages }: ExtendedProduct,
    idx: number
): string =>
    `${AWS_BASE_URL}/galleries/v2/${categoryPath}/${productGalleryImages[idx].name}`;

const Portfolio: React.FC<RouteComponentProps & StateProps & DispatchProps> = ({
    categories,
    onFetchData
}) => {
    const portfolioItemsRef = React.useRef<HTMLDivElement>();
    const [showLightbox, setShowLightbox] = React.useState(false);
    const [selectedImageId, setSelectedImageId] = React.useState<number>(null);
    const [photoIndex, setPhotoIndex] = React.useState<number>(null);
    const [portfolioItems, setPortfolioItems] = React.useState(categories);
    const [selectedCategory, setSelectedCategory] = React.useState<number>(ALL_ID);
    const [selectedProducts, setSelectedProducts] = React.useState<ExtendedProduct[]>(
        []
    );

    const header = (
        <div className='header'>
            <div className='line-scroll center'>
                <span className='line active' />
            </div>
        </div>
    );

    const handleSetSelectedProducts = React.useCallback((products: Product[]) => {
        const updatedProducts = products.map(p =>
            p.productGalleryImages?.length
                ? p
                : {
                      ...p,
                      productGalleryImages: [
                          {
                              id: p.id,
                              name: p.imageName,
                              description: p.description
                          }
                      ]
                  }
        );

        setSelectedProducts(_.shuffle(updatedProducts));
    }, []);

    const handleCategorySelect = React.useCallback(
        (categoryId: number, catProducts: Product[]) =>
            (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();

                setSelectedCategory(categoryId);
                handleSetSelectedProducts(_.shuffle(catProducts));

                document.documentElement.scroll({
                    top: (portfolioItemsRef.current.offsetParent as any).offsetTop - 30,
                    behavior: 'smooth'
                });
            },
        [setSelectedCategory, handleSetSelectedProducts]
    );

    const handleImageClick = React.useCallback(
        (imageId: number) => () => {
            setSelectedImageId(imageId);
            setShowLightbox(true);
            setPhotoIndex(0);
        },
        []
    );

    const handleLightboxClose = React.useCallback(() => {
        setShowLightbox(false);
        setSelectedImageId(null);
        setPhotoIndex(null);
    }, []);

    const handlePhotoIndexChange = React.useCallback(
        (idx: number) => () => {
            setPhotoIndex(idx);
        },
        []
    );

    React.useEffect(() => {
        onFetchData();
    }, [onFetchData]);

    React.useEffect(() => {
        const allProducts = getAllProducts(categories);

        setPortfolioItems(
            categories.length
                ? [
                      {
                          id: ALL_ID,
                          title: 'Όλες οι Εργασίες',
                          subCategories: [],
                          products: allProducts,
                          internalName: ''
                      }
                  ].concat(categories)
                : categories
        );
        handleSetSelectedProducts(_.shuffle(allProducts));
    }, [categories, handleSetSelectedProducts]);

    const getCategories = (
        cat: StateProps['categories'],
        isSubCategory = false,
        parentIds: number[] = []
    ): JSX.Element[] =>
        cat.map(category => {
            const { id, title, subCategories, products } = category;
            const allItems = id === ALL_ID;

            return (
                <div
                    key={allItems ? ALL_ID : id}
                    className={classNames('category', {
                        'sub-category': isSubCategory,
                        selected:
                            parentIds.includes(selectedCategory) ||
                            selectedCategory === id
                    })}
                    onClick={handleCategorySelect(
                        id,
                        allItems ? products : getAllProducts([category], [])
                    )}>
                    {title}
                    {!!subCategories.length &&
                        getCategories(
                            subCategories,
                            !!subCategories.length,
                            parentIds.concat(id)
                        )}
                </div>
            );
        });

    const renderLightbox = () => {
        const selectedProduct = selectedProducts.find(
            ({ id }) => id === selectedImageId
        );
        const numImages = selectedProduct.productGalleryImages.length;
        const prevIndex = (photoIndex + numImages - 1) % numImages;
        const nextIndex = (photoIndex + 1) % numImages;

        return (
            <Lightbox
                wrapperClassName='lightbox'
                animationDuration={500}
                imageTitle={
                    selectedProduct.productGalleryImages[photoIndex].description ||
                    selectedProduct.description ||
                    selectedProduct.title
                }
                imageCaption={`Image ${photoIndex + 1} of ${numImages}`}
                mainSrc={getImageUrl(selectedProduct, photoIndex)}
                nextSrc={
                    numImages > 1 ? getImageUrl(selectedProduct, nextIndex) : undefined
                }
                prevSrc={
                    numImages > 1 ? getImageUrl(selectedProduct, prevIndex) : undefined
                }
                onMovePrevRequest={handlePhotoIndexChange(prevIndex)}
                onMoveNextRequest={handlePhotoIndexChange(nextIndex)}
                onCloseRequest={handleLightboxClose}
            />
        );
    };

    return (
        <Scroll title='Portfolio' header={header} headerClassName='portfolio'>
            {showLightbox && renderLightbox()}
            <div className='portfolio-wrapper'>
                <div className='left'>
                    <div className='page-label'>PORTFOLIO</div>
                    <div className='page-sub-label'>Οι εργασίες μας</div>
                    <div className='categories'>{getCategories(portfolioItems)}</div>
                </div>
                <div className='right' ref={portfolioItemsRef}>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 1, 1050: 2, 1300: 3 }}
                        className='masonry'>
                        <Masonry columnsCount={3} gutter='10px'>
                            {selectedProducts.map(product => (
                                <figure
                                    key={`product_${product.id}`}
                                    className={`product ${product.id}`}
                                    onClick={handleImageClick(product.id)}>
                                    <img
                                        src={`${AWS_BASE_URL}/galleries/v2/${product.categoryPath}/${product.imageName}`}
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            minWidth: 170
                                        }}
                                    />
                                    <figcaption>
                                        <h3 className='title' title={product.title}>
                                            {product.title}
                                        </h3>
                                        <div className='details'>
                                            <div>Κατηγορία: {product.category}</div>
                                            <div
                                                className='description'
                                                title={product.description}>
                                                {product.description}
                                            </div>
                                            {!_.isNil(product.price) && (
                                                <>
                                                    <br />
                                                    Τιμή: {product.price}
                                                </>
                                            )}
                                        </div>
                                    </figcaption>
                                </figure>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </Scroll>
    );
};

export default connect<StateProps, DispatchProps>(
    (state: Store) => ({
        categories: state.app.categories || []
    }),
    dispatch => ({
        onFetchData: () => dispatch<any>(fetchAll(false))
    })
)(Portfolio);

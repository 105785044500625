import { COOKIE_TOKEN_KEY } from './Constants';
import history from './history';

export const getTokenFromCookies = (): string => {
    const cookie = document.cookie
        .split('; ')
        .find(c => c.startsWith(`${COOKIE_TOKEN_KEY}=`));
    return cookie ? cookie.split('=')[1] : undefined;
};

export const required = (value: string) => (value ? undefined : 'Υποχρεωτικό πεδίο');

export const deleteCookieAndRedirect = () => {
    document.cookie = `${COOKIE_TOKEN_KEY}=`;
    history.push('/login');
};

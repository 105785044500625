import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FontAwesomeIcon from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { ACCOUNT_URL, CATEGORIES_URL, PRODUCTS_URL } from './Constants';

const HeaderMenu: React.FC<{}> = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleMenuItemClick = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    return (
        <div>
            <FontAwesomeIcon
                id='menu-dropdown'
                aria-controls='menu'
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                name='bars'
                className='menu-icon'
                onClick={handleClick}
            />
            <Menu
                id='menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className='admin-menu'
                MenuListProps={{ 'aria-labelledby': 'menu-dropdown' }}>
                <Link className='link' to={CATEGORIES_URL}>
                    <MenuItem className='menu-item' onClick={handleMenuItemClick}>
                        Κατηγορίες προϊόντων
                    </MenuItem>
                </Link>
                <Link className='link' to={PRODUCTS_URL}>
                    <MenuItem className='menu-item' onClick={handleMenuItemClick}>
                        Προϊόντα
                    </MenuItem>
                </Link>
                <Link className='link' to={ACCOUNT_URL}>
                    <MenuItem className='menu-item' onClick={handleMenuItemClick}>
                        Ρυθμίσεις λογαριασμού
                    </MenuItem>
                </Link>
            </Menu>
        </div>
    );
};

export default HeaderMenu;

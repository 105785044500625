import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps, RouteComponentProps, Redirect } from 'react-router-dom';
import { AppStore, Store } from './types';
import { fetchAll } from './actions/Actions';
import { getTokenFromCookies } from './helpers';

interface DispatchProps {
    onFetchData: () => Promise<unknown>;
}

interface StateProps {
    categories: AppStore['categories'];
    user: AppStore['user'];
}

interface OwnProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps>;
}

const Body: React.FC<StateProps & DispatchProps & OwnProps> = ({
    // eslint-disable-next-line destructuring/no-rename
    component: Component,
    categories,
    user,
    onFetchData,
    ...rest
}) => {
    React.useEffect(() => {
        onFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!categories || !user) {
        return null;
    }

    return (
        <Route
            {...rest}
            // eslint-disable-next-line react/jsx-no-bind
            render={props => <Component {...props} />}
        />
    );
};

const BodyComp = connect(
    (state: Store): StateProps => ({
        categories: state.app.categories,
        user: state.app.user
    }),
    (dispatch): DispatchProps => ({
        onFetchData: () => dispatch<any>(fetchAll())
    })
)(Body);

const AuthorizedRoute: React.FC<OwnProps> = ({
    // eslint-disable-next-line destructuring/no-rename
    component: Component,
    ...rest
}) => (
    <Route
        {...rest}
        // eslint-disable-next-line react/jsx-no-bind
        render={props =>
            getTokenFromCookies() ? (
                <BodyComp {...props} component={Component} />
            ) : (
                <Redirect to='/login' />
            )
        }
    />
);

export default AuthorizedRoute;

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import FontAwesome from 'react-fontawesome';

interface Props {
    title: string;
    message: string;
    icon?: string;
    iconTitle?: string;
    disabled?: boolean;
    onAccept: () => void;
}

const DeleteButton: React.FC<Props> = ({
    title,
    message,
    disabled,
    icon,
    iconTitle,
    onAccept
}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = React.useCallback(() => {
        if (disabled) {
            return;
        }

        setOpen(true);
    }, [disabled]);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    const handleAccept = React.useCallback(() => {
        handleClose();
        onAccept();
    }, [handleClose, onAccept]);

    return (
        <>
            {icon ? (
                <FontAwesome
                    className={classNames('icon', { disabled })}
                    name={icon}
                    title={iconTitle}
                    onClick={handleClickOpen}
                />
            ) : (
                <button
                    type='button'
                    className='delete'
                    onClick={handleClickOpen}
                    disabled={disabled}>
                    ΔΙΑΓΡΑΦΗ
                </button>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                className='delete-prompt'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id='alert-dialog-description'
                        dangerouslySetInnerHTML={{ __html: message }}
                        style={{ wordBreak: 'break-all' }}
                    />
                </DialogContent>
                <DialogActions>
                    <button onClick={handleAccept} type='button' className='accept'>
                        ΔΙΑΓΡΑΦΗ
                    </button>
                    <button onClick={handleClose} type='button' className='cancel'>
                        ΑΚΥΡΟ
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteButton;

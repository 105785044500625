import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { AppStore, Store } from './types';
import HeaderMenu from './Menu';
import { ACCOUNT_URL, CATEGORIES_URL, PRODUCTS_URL } from './Constants';
import Categories from './AdminCategories';
import Products from './AdminProducts';
import { setCategories } from './actions/Actions';
import Account from './Account';

const TITLES = {
    [CATEGORIES_URL]: 'ΚΑΤΗΓΟΡΙΕΣ',
    [PRODUCTS_URL]: 'ΠΡΟΪΟΝΤΑ',
    [ACCOUNT_URL]: 'ΡΥΘΜΙΣΕΙΣ ΛΟΓΑΡΙΑΣΜΟΥ'
};

interface StateProps {
    categories: AppStore['categories'];
    user: AppStore['user'];
}

interface DispatchProps {
    onCategoriesUpdate: (categories: AppStore['categories']) => void;
}

const Admin: React.FC<
    RouteComponentProps<{ id: string }> & StateProps & DispatchProps
> = ({ match, history, categories, user, onCategoriesUpdate }) => {
    React.useEffect(() => {
        if (!match.params?.id || !TITLES[match.url]) {
            history.replace(CATEGORIES_URL);
        }
    }, [match, history]);

    const renderComponent = React.useCallback(() => {
        switch (match.url) {
            case CATEGORIES_URL:
                return (
                    <Categories
                        categories={categories}
                        onCategoriesUpdate={onCategoriesUpdate}
                    />
                );
            case PRODUCTS_URL:
                return (
                    <Products
                        categories={categories}
                        onCategoriesUpdate={onCategoriesUpdate}
                    />
                );
            case ACCOUNT_URL:
                return <Account />;
            default:
                return null;
        }
    }, [categories, match.url, onCategoriesUpdate]);

    return (
        <div className='admin'>
            <div className='title'>{TITLES[match.url]}</div>
            <div className='body'>
                <HeaderMenu />
                {renderComponent()}
            </div>
            <div className='admin-footer'>
                <div className='app-footer'>
                    <div className='user footer-item'>
                        ΣΥΝΔΕΔΕΜΕΝΟΣ ΧΡΗΣΤΗΣ {user.fullName} ({user.userName})
                    </div>
                    <div className='current-time footer-item'>
                        {moment().format('DD-MM-YYYY')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect<StateProps, DispatchProps>(
    (state: Store) => ({
        categories: state.app.categories,
        user: state.app.user
    }),
    dispatch => ({
        onCategoriesUpdate: categories => dispatch(setCategories(categories))
    })
)(Admin);

import { FormApi } from 'final-form';
import * as React from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { enqueueNotification } from './actions/Actions';
import Scroll from './Scroll';
import { INotification } from './types';
import * as Api from './Api';
import { required } from './helpers';

interface FormValues {
    name: string;
    email: string;
    subject: string;
    body: string;
}

type DispatchProps = {
    onEnqueueNotification: (notification: INotification) => void;
};

const Contact: React.FC<RouteComponentProps & DispatchProps> = ({
    onEnqueueNotification
}) => {
    const renderForm = React.useCallback(
        ({
            handleSubmit,
            hasValidationErrors,
            validating,
            submitting
        }: FormRenderProps) => (
            <form onSubmit={handleSubmit} className='contact-form'>
                <div className='single-line-wrap'>
                    <Field name='name' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} placeholder='Ονοματεπώνυμο' />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                    <Field name='email' validate={required}>
                        {({ input, meta }) => (
                            <div className='field-input'>
                                <input {...input} placeholder='Email' type='email' />
                                {meta.touched && meta.error && (
                                    <FontAwesome
                                        name='exclamation-circle'
                                        title={meta.error}
                                        className='error'
                                    />
                                )}
                            </div>
                        )}
                    </Field>
                </div>
                <Field name='subject' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <input {...input} placeholder='Θέμα' />
                            {meta.touched && meta.error && (
                                <FontAwesome
                                    name='exclamation-circle'
                                    title={meta.error}
                                    className='error'
                                />
                            )}
                        </div>
                    )}
                </Field>
                <Field name='body' validate={required}>
                    {({ input, meta }) => (
                        <div className='field-input'>
                            <textarea
                                {...input}
                                placeholder='Το κείμενό σας'
                                rows={8}
                            />
                            {meta.touched && meta.error && (
                                <FontAwesome
                                    name='exclamation-circle'
                                    title={meta.error}
                                    className='error'
                                />
                            )}
                        </div>
                    )}
                </Field>
                <div className='action-buttons'>
                    <button
                        type='submit'
                        disabled={submitting || validating || hasValidationErrors}>
                        Αποστολή <FontAwesome name='long-arrow-right' />
                    </button>
                </div>
            </form>
        ),
        []
    );

    const handleSubmit = React.useCallback(
        (values: FormValues, form: FormApi) =>
            Api.contact(values)
                .then(res => {
                    if (!res.ok) {
                        onEnqueueNotification({
                            key: new Date().getTime(),
                            message: `Συνέβη ένα σφάλμα... Κωδικός: ${res.status} Μήνυμα: ${res.statusText}`,
                            options: {
                                variant: 'error'
                            }
                        });
                        return;
                    }
                    onEnqueueNotification({
                        key: new Date().getTime(),
                        message: '✓ Το μήνυμά σας στάλθηκε επιτυχώς...',
                        options: {
                            variant: 'success',
                            autoHideDuration: 5000
                        }
                    });
                })
                .finally(() => {
                    form.reset({});
                    ['name', 'email', 'subject', 'body'].forEach(key => {
                        form.resetFieldState(key);
                    });
                }),
        [onEnqueueNotification]
    );

    const header = (
        <div className='header'>
            <div className='label center'>Ελάτε σε Επαφή</div>
            <div className='sub-label center'>
                Η πραγματοποίηση της δικής σας ιδέας είναι μερικά βήματα μακρυά
            </div>
            <div className='sub-label center'>Επικοινωνήστε μαζί μας αμέσως...</div>
            <div className='line-scroll center'>
                <span className='line active' />
            </div>
        </div>
    );

    return (
        <Scroll header={header} headerClassName='contact' title='Επικοινωνία'>
            <div className='contact-wrapper'>
                <div className='left'>
                    <div className='page-label'>Ελάτε σε Επαφή</div>
                    <div className='page-sub-label'>Επικοινωνήστε μαζί μας</div>
                    <div className='address'>
                        <b>Καταστήματα Θεσσαλονίκης</b>
                        <div>
                            Κεντρικό:{' '}
                            <a
                                href='https://goo.gl/maps/Xpv8dMx97ETVd2Po7'
                                rel='noreferrer'
                                target='_blank'>
                                Ομήρου 36, 54638
                            </a>
                        </div>
                        <div>
                            Εργαστήρι:{' '}
                            <a
                                href='https://goo.gl/maps/2oipVebHhiipRk6M8'
                                rel='noreferrer'
                                target='_blank'>
                                Ύδρας 31, 54638
                            </a>
                        </div>
                    </div>
                    <div className='phone-numbers'>
                        <div>Κινητό: 6932130592</div>
                        <div>Κινητό: 6973043294</div>
                        <div>Σταθερό: 2310935369</div>
                    </div>
                    <div className='emails'>
                        <div>
                            <a href='mailto:info@bosnakis.gr'>info@bosnakis.gr</a>
                        </div>
                        <div>
                            <a href='mailto:bosnakis@otenet.gr'>bosnakis@otenet.gr</a>
                        </div>
                    </div>
                    <div className='icons'>
                        <a href='mailto:info@bosnakis.gr'>
                            <FontAwesome name='envelope-o' className='icon' />
                        </a>
                        <a href='mailto:bosnakis@otenet.gr'>
                            <FontAwesome name='envelope-o' className='icon' />
                        </a>
                        <a
                            href='https://www.facebook.com/%CE%92%CE%B9%CE%B2%CE%BB%CE%B9%CE%BF%CE%B4%CE%B5%CF%84%CE%B5%CE%AF%CE%BF-%CE%9C%CF%80%CE%BF%CF%83%CE%BD%CE%AC%CE%BA%CE%B7-211761432656847'
                            rel='noreferrer'
                            target='_blank'>
                            <FontAwesome name='facebook' className='icon' />
                        </a>
                    </div>
                </div>
                <div className='right'>
                    <div className='contact-right'>
                        <div className='page-label'>Επικοινωνήστε μαζί μας</div>
                        <div className='message'>αφήστε μας ένα μήνυμα</div>
                    </div>
                    <Form render={renderForm} onSubmit={handleSubmit} />
                </div>
            </div>
        </Scroll>
    );
};

export default connect<{}, DispatchProps>(
    null,
    (dispatch): DispatchProps => ({
        onEnqueueNotification: notification =>
            dispatch(enqueueNotification(notification))
    })
)(Contact);

import { Store } from './types';

const STORE: Store = {
    app: {
        notifications: [],
        categories: null,
        user: null
    }
};

export default STORE;

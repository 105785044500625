import { ThunkAction as ThunkActionCreator } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SnackbarKey } from 'notistack';
import { AppStore, INotification, Store } from '../types';

export enum ActionType {
    REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
    ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION',
    SET_CATEGORIES = 'SET_CATEGORIES',
    SET_USER = 'SET_USER'
}

interface CloseNotification {
    type: ActionType.REMOVE_NOTIFICATION;
    payload: SnackbarKey;
}

interface EnqueueNotification {
    type: ActionType.ENQUEUE_NOTIFICATION;
    payload: INotification;
}

interface SetCategories {
    type: ActionType.SET_CATEGORIES;
    payload: AppStore['categories'];
}

interface SetUser {
    type: ActionType.SET_USER;
    payload: AppStore['user'];
}

export type Action = CloseNotification | EnqueueNotification | SetCategories | SetUser;

export type ThunkAction<R = void> = ThunkActionCreator<R, Store, unknown, AnyAction>;
